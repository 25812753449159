import React, { lazy, Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import { getKcContext } from 'keycloakify'

import { AuthService } from 'lib/auth'

import { KcContext } from './keycloak/kcContext'

const { kcContext } = getKcContext<KcContext>({
  //mockPageId: 'saml-post-form.ftl',
  mockData: [
    {
      pageId: 'saml-post-form.ftl',
      samlPost: {
        url: '/'
      }
    }
  ]
})

const App = lazy(() => import('./App'))
const KcApp = lazy(() => import('./keycloak/KcApp'))

if (kcContext !== undefined) {
  ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <Suspense>
      <KcApp kcContext={kcContext} />
    </Suspense>
  )
}

if (kcContext === undefined) {
  AuthService.initKeycloak(() =>
    ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
      <Suspense>
        <App />
      </Suspense>
    )
  )
}
