const TESTER =
  /^[-!#$%&'*+\\/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+\\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/

export const validateEmail = (email: string) => TESTER.test(email)

export const validateEmailWithMessage = (email: string) =>
  !validateEmail(email) ? 'Bitte geben Sie eine gültige E-Mail-Adresse ein.' : undefined

const FORBITTEN_VERTEILER = ['marketing', 'support', 'service']

export const validateEmailVerteilerWithMessage = (email: string) =>
  FORBITTEN_VERTEILER.some((v) => {
    console.log(v, email, email.startsWith(v))
    return email.startsWith(v)
  })
    ? 'Bitte geben Sie keine Verteiler-Adresse an.'
    : undefined
