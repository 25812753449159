import { CourseTypeEnum, ParticipationStatusEnum } from '@cleverbits-gmbh/iwimp-typescript-api'

export const displayCourseType = (type: CourseTypeEnum): string => {
  switch (type) {
    case 'E_LEARNING':
      return 'E-LEARNING'
    case 'SEMINAR':
      return 'SEMINAR'
    default:
      return type
  }
}

export const displayCourseProgress = (progress: number): string => {
  return `${(progress * 100).toFixed(2)}%`
}

export const displayParticipationStatus = (status: ParticipationStatusEnum): string => {
  switch (status) {
    case 'COMPLETED':
      return 'Abgeschlossen'
    case 'ADDED':
      return 'Hinzugefügt'
    case 'INVITED':
      return 'Eingeladen'
    case 'STARTED':
      return 'Angefangen'
    case 'INVITATION_ACCEPTED':
      return 'Einladung akzeptiert'
    case 'JOINED':
      return 'Beigetreten'
    case 'DECLINED':
      return 'Abgelehnt'
  }
}
