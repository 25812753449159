import { useMutation, useQueryClient } from '@tanstack/react-query'

import { adminService } from 'lib/api/AdminService'
import { useMessageActions } from 'lib/state/useMessageState'

const triggerMarketingExport = async () => await adminService.triggerMarketingExport()

export const useAdminTriggerMarketingExport = () => {
  const client = useQueryClient()
  const { addMessage } = useMessageActions()
  return useMutation(triggerMarketingExport, {
    onSuccess: async () => {
      await client.invalidateQueries(['/admin/exports'])
      addMessage({ type: 'success', autoCloseSeconds: 5, text: 'Manueller Marketing Export wurde erstellt.' })
    },
    onError: () => addMessage({ type: 'error', text: 'Manueller Marketing Export konnte nicht erstellt werden.' })
  })
}
