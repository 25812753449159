import { useMutation } from '@tanstack/react-query'

import { profileService } from 'lib/api/ProfileService'
import { useMessageActions } from 'lib/state'

const updateProfilePassword = (password: string) => profileService.updateProfilePassword(password)

export const useProfilePasswordUpdate = (onSuccess: () => void) => {
  const { addMessage } = useMessageActions()
  return useMutation(updateProfilePassword, {
    onSuccess: () => {
      onSuccess()
      addMessage({ type: 'success', autoCloseSeconds: 5, text: 'Passwort erfolgreich geändert.' })
    },
    onError: () => {
      addMessage({ type: 'error', autoCloseSeconds: 5, text: 'Password konnte nicht geändert werden.' })
    }
  })
}
