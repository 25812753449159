import { Configuration } from '@cleverbits-gmbh/iwimp-typescript-api'

import { AuthService } from 'lib/auth'

export abstract class ServiceBase {
  protected authenticated(): Configuration {
    return new Configuration({
      basePath: process.env.REACT_APP_PUBLIC_URL,
      headers: { ['Authorization']: `Bearer ${AuthService.getToken()}` }
    })
  }
}
