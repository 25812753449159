import { GetMarketingExportsRequest } from '@cleverbits-gmbh/iwimp-typescript-api'
import { useQuery } from '@tanstack/react-query'

import { PaginationState } from 'components/container'
import { adminService } from 'lib/api/AdminService'

const fetcher = async (state: PaginationState) => {
  const req = convertPagination(state)
  return adminService.getMarketingExports(req)
}

export const useAdminMarketingExports = (state: PaginationState) => {
  return useQuery(['/admin/exports', state], () => fetcher(state))
}

const convertPagination = ({ includeDeleted, page, size, sortOrder }: PaginationState): GetMarketingExportsRequest => {
  return { page, size, sort: sortOrder.sort, order: sortOrder.order, includeDeleted }
}
