import { AdminInfoResponse, FAQGroupResponse, SiteConfigApi } from '@cleverbits-gmbh/iwimp-typescript-api'

import { ServiceBase } from 'lib/api/ServiceBase'

class SiteConfigService extends ServiceBase {
  async getAdminInfo(): Promise<AdminInfoResponse> {
    return new SiteConfigApi(this.authenticated()).getAdminInfo()
  }

  async getFAQs(): Promise<FAQGroupResponse[]> {
    return new SiteConfigApi(this.authenticated()).getFAQs()
  }
}

export const siteConfigService = new SiteConfigService()
