import { Validate } from 'react-hook-form'

export const validateUrl: Validate<string> = (value) => {
  if (!value) return true
  try {
    new URL(value)
    return true
  } catch (e) {
    return 'Url muss valide sein.'
  }
}
