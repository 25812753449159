import { useMutation, useQueryClient } from '@tanstack/react-query'

import { certificateService } from 'lib/api/CertificateService'
import { useMessageActions } from 'lib/state'

const deleteCertificate = (id: number) => certificateService.delete(id)

export const useCertificateDelete = () => {
  const client = useQueryClient()
  const { addMessage } = useMessageActions()
  return useMutation(deleteCertificate, {
    onSuccess: async () => {
      await client.invalidateQueries(['/certificates'])
      await client.invalidateQueries(['/admin/user'])
      addMessage({ type: 'success', autoCloseSeconds: 5, text: 'Zertifikat erfolgreich gelöscht.' })
    },
    onError: () => {
      addMessage({ type: 'error', autoCloseSeconds: 5, text: 'Zertifikat konnte nicht gelöscht werden.' })
    }
  })
}
