import {
  AdminUserResponse,
  CertificateResponse,
  ParticipationResponse,
  ParticipationStatusEnum,
  UserRoleEnum
} from '@cleverbits-gmbh/iwimp-typescript-api'

export const userRoleToLabel = (role: UserRoleEnum): string => {
  switch (role) {
    case UserRoleEnum.SUPPORT:
      return 'Support'
    case UserRoleEnum.EDITOR:
      return 'Editor'
    case UserRoleEnum.ADMIN:
      return 'Admin'
    case UserRoleEnum.USER:
      return 'User'
  }
}

export const getCertificates = (user: AdminUserResponse): CertificateResponse[] => {
  return user.certificates
}

export const getStartedCourses = (user: AdminUserResponse): ParticipationResponse[] => {
  return user.participations.filter((p) => p.status !== ParticipationStatusEnum.COMPLETED)
}

export const getFinishedCourses = (user: AdminUserResponse): ParticipationResponse[] => {
  return user.participations.filter((p) => p.status === ParticipationStatusEnum.COMPLETED)
}
