import { AdminUserResponse } from '@cleverbits-gmbh/iwimp-typescript-api'
import { useMutation } from '@tanstack/react-query'

import { adminService } from 'lib/api/AdminService'
import { useMessageActions } from 'lib/state'

type UpdateUserPasswordParams = { user: AdminUserResponse; password: string }

const updateUserPassword = ({ user, password }: UpdateUserPasswordParams) => {
  return adminService.updateUserPassword(user, password)
}

export const useAdminUsersPasswordUpdate = () => {
  const { addMessage } = useMessageActions()
  return useMutation(updateUserPassword, {
    onSuccess: async () => {
      addMessage({ type: 'success', autoCloseSeconds: 5, text: 'Benutzerpasswort erfolgreich aktualisiert.' })
    },
    onError: () => {
      addMessage({
        type: 'error',
        autoCloseSeconds: 5,
        text: 'Benutzerpasswort konnten nicht aktualisiert werden.'
      })
    }
  })
}
