import { AdminInfoRequest, AdminInfoUpdateRequest } from '@cleverbits-gmbh/iwimp-typescript-api'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { adminService } from 'lib/api/AdminService'
import { useMessageActions } from 'lib/state'

type AdminInfoUpdateParams = {
  id: number | undefined
  req: AdminInfoRequest | AdminInfoUpdateRequest
}
const updateAdminInfo = ({ id, req }: AdminInfoUpdateParams) => {
  if (id) {
    return adminService.updateAdminInfo(id, req)
  } else {
    return adminService.createAdminInfo(req)
  }
}

export const useAdminInfoUpdate = () => {
  const client = useQueryClient()
  const { addMessage } = useMessageActions()
  return useMutation(updateAdminInfo, {
    onSuccess: async () => {
      await client.invalidateQueries(['/admin/settings'])
      addMessage({ type: 'success', autoCloseSeconds: 5, text: 'Admin Information erfolgreich aktualisiert.' })
    },
    onError: () => {
      addMessage({
        type: 'error',
        autoCloseSeconds: 5,
        text: 'Admin Information konnte nicht aktualisiert werden.'
      })
    }
  })
}
