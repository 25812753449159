import { useMutation } from '@tanstack/react-query'

import { profileService } from 'lib/api/ProfileService'
import { useLogout } from 'lib/auth'
import { useMessageActions } from 'lib/state/useMessageState'

const deleteSelf = async () => await profileService.deleteSelf()

export const useProfileDelete = () => {
  const logout = useLogout()
  const { addMessage } = useMessageActions()
  return useMutation(deleteSelf, {
    onSuccess: () => logout('/profil-geloescht'),
    onError: () => addMessage({ text: 'Account konnte nicht gelöscht werden.', type: 'error' })
  })
}
