import { AdminUserResponse, GetUsersRequest } from '@cleverbits-gmbh/iwimp-typescript-api'
import { useQuery } from '@tanstack/react-query'

import { PaginationState } from 'components/container'
import { adminService } from 'lib/api/AdminService'

const fetcher = async (state: PaginationState) => {
  const req = convertPagination(state)
  return adminService.getUsers(req)
}

export const useAdminUsers = (state: PaginationState) => {
  return useQuery(['/admin/users', state], () => fetcher(state))
}
export const useAdminUser = (id?: number) => {
  return useQuery(
    ['/admin/user'],
    (): Promise<AdminUserResponse | undefined> =>
      id ? adminService.getUser(id) : new Promise((resolve) => resolve(undefined))
  )
}

const convertPagination = ({ query, page, size, sortOrder, includeDeleted }: PaginationState): GetUsersRequest => {
  return { query, page, size, sort: sortOrder.sort, order: sortOrder.order, includeDeleted }
}
