import { AdminUserResponse } from '@cleverbits-gmbh/iwimp-typescript-api'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { adminService } from 'lib/api/AdminService'
import { useMessageActions } from 'lib/state'

type UpdateUserAllInclusiveParams = { user: AdminUserResponse; isAllInclusive: boolean }

const updateUserAllInclusive = ({ user, isAllInclusive }: UpdateUserAllInclusiveParams) =>
  adminService.updateUserAllInclusive(user, isAllInclusive)

export const useAdminUsersAllInclusive = () => {
  const client = useQueryClient()
  const { addMessage } = useMessageActions()
  return useMutation(updateUserAllInclusive, {
    onSuccess: async () => {
      await client.invalidateQueries(['/admin/users'])
      addMessage({
        type: 'success',
        autoCloseSeconds: 5,
        text: 'Benutzer All-Inclusive Status erfolgreich gewährt.'
      })
    },
    onError: () => {
      addMessage({
        type: 'error',
        autoCloseSeconds: 5,
        text: 'Benutzer konnte kein All-Inclusive Status gewährt werden.'
      })
    }
  })
}
