import { AdminMessageResponse, ApiResponse, UsersApi } from '@cleverbits-gmbh/iwimp-typescript-api'

import { ServiceBase } from 'lib/api/ServiceBase'
import { Discount, User } from 'lib/model'

class ProfileService extends ServiceBase {
  async self(): Promise<User> {
    return new UsersApi(this.authenticated()).getSelf()
  }

  async getAdminMessages(): Promise<AdminMessageResponse[]> {
    return new UsersApi(this.authenticated()).getAdminMessagesForUser()
  }

  async deleteSelf(): Promise<void> {
    return new UsersApi(this.authenticated()).deleteSelf()
  }

  async updateProfile(userProfile: User): Promise<void> {
    return new UsersApi(this.authenticated()).updateSelf({ userUpdateRequest: userProfile })
  }

  async updateProfileDiscount(discount: Discount): Promise<ApiResponse<void>> {
    return new UsersApi(this.authenticated()).updateSelfDiscountRaw({ userDiscountUpdateRequest: discount })
  }

  async updateProfileDiscountById(id: number, discount: Discount): Promise<ApiResponse<void>> {
    return new UsersApi(this.authenticated()).updateDiscountByUserIdRaw({ id, userDiscountUpdateRequest: discount })
  }

  async updateProfilePassword(password: string): Promise<void> {
    return new UsersApi(this.authenticated()).updateSelfPassword({ userPasswordUpdateRequest: { password } })
  }

  async deleteProfileDiscount(userId: number): Promise<ApiResponse<void>> {
    return new UsersApi(this.authenticated()).deleteDiscountByUserIdRaw({ id: userId })
  }

  async readAdminMessage(id: number) {
    return new UsersApi(this.authenticated()).markAdminMessageAsRead({ id })
  }
}

export const profileService = new ProfileService()
