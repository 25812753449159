import { CertificateSourceEnum } from '@cleverbits-gmbh/iwimp-typescript-api'

export const displayCertificateSource = (source: CertificateSourceEnum): string => {
  switch (source) {
    case 'RETEACH_IO':
      return 'reteach.io'
    case 'SELF_UPLOAD':
      return 'Selbst hochgeladen'
  }
}
