import { useMutation, useQueryClient } from '@tanstack/react-query'

import { profileService } from 'lib/api/ProfileService'
import { Discount } from 'lib/model'
import { useMessageActions } from 'lib/state'

const updateProfileDiscount = (discount: Discount) => profileService.updateProfileDiscount(discount)

export const useProfileDiscountUpdate = (onSuccess: () => void) => {
  const client = useQueryClient()
  const { addMessage } = useMessageActions()
  return useMutation(updateProfileDiscount, {
    onSuccess: async () => {
      onSuccess()
      await client.invalidateQueries(['/profile'])
      addMessage({ type: 'success', autoCloseSeconds: 5, text: 'Vorteilscode erfolgreich aktualisiert.' })
    },
    onError: (error: { response: Response }) => {
      const { response } = error
      addMessage({
        type: 'error',
        autoCloseSeconds: 5,
        text:
          response?.status === 422
            ? 'Das Kontingent des Vorteilscodes ist ausgeschöpft.'
            : 'Vorteilscode konnte nicht aktualisiert werden. Bitte überprüfen Sie die immowelt Kundennummer und den Vorteilscode.'
      })
    }
  })
}
