import { PropsWithChildren, useEffect } from 'react'

import { useProfile } from 'lib/api'
import { useAuthState } from 'lib/auth/useAuthState'

export const AuthProvider = ({ children }: PropsWithChildren) => {
  const { data } = useProfile()
  const { setUser } = useAuthState()

  useEffect(() => {
    setUser(data ?? null)
  }, [data])

  return <>{children}</>
}
