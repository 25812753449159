import { FAQUpdateRequest } from '@cleverbits-gmbh/iwimp-typescript-api'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { adminService } from 'lib/api/AdminService'
import { useMessageActions } from 'lib/state'

const updateFAQs = (req: FAQUpdateRequest) => adminService.updateFAQs(req)

export const useAdminFAQsUpdate = () => {
  const client = useQueryClient()
  const { addMessage } = useMessageActions()
  return useMutation(updateFAQs, {
    onSuccess: async () => {
      await client.invalidateQueries(['/admin/faqs'])
      addMessage({ type: 'success', autoCloseSeconds: 5, text: 'FAQs erfolgreich aktualisiert.' })
    },
    onError: () => {
      addMessage({ type: 'error', autoCloseSeconds: 5, text: 'FAQs konnten nicht aktualisiert werden.' })
    }
  })
}
