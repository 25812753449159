import { useMutation, useQueryClient } from '@tanstack/react-query'

import { profileService } from 'lib/api/ProfileService'
import { User } from 'lib/model'
import { useMessageActions } from 'lib/state'

const updateProfile = (profile: User) => profileService.updateProfile(profile)

interface UpdateProfileOptions {
  successMessage?: string
  errorMessage?: string
}

export const useProfileUpdate = ({
  successMessage = 'Profil erfolgreich aktualisiert.',
  errorMessage = 'Profil konnte nicht aktualisiert werden.'
}: UpdateProfileOptions = {}) => {
  const client = useQueryClient()
  const { addMessage } = useMessageActions()

  return useMutation(updateProfile, {
    onSuccess: async () => {
      await client.invalidateQueries(['/profile'])
      addMessage({ type: 'success', autoCloseSeconds: 5, text: successMessage })
    },
    onError: () => {
      addMessage({ type: 'error', autoCloseSeconds: 5, text: errorMessage })
    }
  })
}
