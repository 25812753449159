import { useMutation, useQueryClient } from '@tanstack/react-query'

import { certificateService } from 'lib/api/CertificateService'
import { OnMultiProgress } from 'lib/api/FileService'
import { useMessageActions } from 'lib/state'
import { noop } from 'lib/utils'

type UploadFileOptions = {
  files: File[]
  onProgress?: OnMultiProgress
}

const uploadFiles = async ({ files, onProgress = noop }: UploadFileOptions): Promise<void> => {
  await certificateService.upload(files, onProgress)
}

export const useCertificatesUpload = () => {
  const client = useQueryClient()
  const { addMessage } = useMessageActions()
  return useMutation(uploadFiles, {
    onSuccess: async () => {
      await client.invalidateQueries(['/certificates'])
      addMessage({ type: 'success', autoCloseSeconds: 5, text: 'Zertifikate erfolgreich hochgeladen.' })
    },
    onError: () => {
      addMessage({ type: 'error', autoCloseSeconds: 5, text: 'Zertifikate konnten nicht hochgeladen werden.' })
    }
  })
}
