import immer from 'immer'

export const paginate = <T>(array: T[], page: number, rows: number): T[] => {
  return array.slice(page * rows, page * rows + rows)
}

export const orderBy = <T>(array: T[], field: keyof T, order: 'asc' | 'desc' = 'asc'): T[] => {
  const first = order === 'asc' ? 0 : 1
  const second = order === 'asc' ? 1 : 0
  return immer(array, (draft: T[]) => {
    draft.sort((a, b) => (a[field] < b[field] ? first : second))
  })
}

export const move = <T>(array: T[], from: number, to: number): T[] => {
  const copy = [...array]
  const removed = copy.splice(from, 1)[0]
  copy.splice(to, 0, removed)
  return copy
}
