import { format } from 'date-fns'

export const formatDate = (date: Date | number | undefined | null): string => {
  if (!date) return '-'
  return format(date, 'dd.MM.yyyy')
}

export const formatTime = (date: Date | number | undefined | null): string => {
  if (!date) return '-'
  return format(date, 'HH:mm')
}

export const formatDateTime = (date: Date | number | undefined | null): string => {
  if (!date) return '-'
  return format(date, 'dd.MM.yyyy HH:mm')
}

export const compareDateDesc = (date1: Date, date2: Date) => {
  return date2.getTime() - date1.getTime()
}
export const compareDateAsc = (date1: Date, date2: Date) => {
  return date1.getTime() - date2.getTime()
}
