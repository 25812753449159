import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Theme, useMediaQuery } from '@mui/material'
import create from 'zustand'

type DashboardState = {
  isSidebarExpanded: boolean
  setSidebarExpanded: (value: boolean) => void
}

const useDashboardState = create<DashboardState>((set) => ({
  isSidebarExpanded: true,
  setSidebarExpanded: (isSidebarExpanded) => set({ isSidebarExpanded })
}))

export const useSidebarState = () => {
  const { isSidebarExpanded, setSidebarExpanded } = useDashboardState()
  const location = useLocation()
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'), { noSsr: true })
  useEffect(() => {
    if (!isMobile) {
      return
    }
    setSidebarExpanded(false)
  }, [location, isMobile])

  return {
    isExpanded: isSidebarExpanded,
    toggle: () => setSidebarExpanded(!isSidebarExpanded)
  }
}
