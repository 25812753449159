import { AdminUserResponse } from '@cleverbits-gmbh/iwimp-typescript-api'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { adminService } from 'lib/api/AdminService'
import { useMessageActions } from 'lib/state'

const deleteUser = (user: AdminUserResponse) => {
  return adminService.deleteUser(user)
}

export const useAdminUsersDelete = () => {
  const client = useQueryClient()
  const { addMessage } = useMessageActions()
  return useMutation(deleteUser, {
    onSuccess: async () => {
      await client.invalidateQueries(['/admin/users'])
      addMessage({ type: 'success', autoCloseSeconds: 5, text: 'Benutzer erfolgreich gelöscht.' })
    },
    onError: () => {
      addMessage({
        type: 'error',
        autoCloseSeconds: 5,
        text: 'Benutzer konnte nicht gelöscht werden.'
      })
    }
  })
}
