import { AlertColor } from '@mui/material'
import create from 'zustand'

interface MessageState {
  messages: Message[]
}

export interface MessageOptions {
  closable?: boolean
  autoCloseSeconds?: number
  action?: {
    actionTitle: string
    actionMethod: (message: Message) => void
  }
}

export interface Message extends MessageOptions {
  type: AlertColor
  text: string
}

interface MessageActions {
  addMessage: (message: Message) => void
  closeMessage: (message: Message) => void
}

const useMessageState = create<MessageState & MessageActions>((set) => ({
  messages: [],
  addMessage: (message) => {
    set((state) => {
      if (message.autoCloseSeconds) {
        setTimeout(() => state.closeMessage(message), message.autoCloseSeconds * 1000)
      }
      return { messages: [message, ...state.messages].slice(0, 3) }
    })

    return message
  },
  closeMessage: (message) =>
    set((state) => ({
      messages: state.messages.filter((m) => m !== message)
    }))
}))

export const useMessageActions = () =>
  useMessageState((s) => ({
    addMessage: s.addMessage,
    closeMessage: s.closeMessage
  }))

export const useMessages = () =>
  useMessageState((s) => ({
    messages: s.messages,
    closeMessage: s.closeMessage
  }))
