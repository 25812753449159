import {
  CourseResponse,
  CoursesApi,
  CourseTypeEnum,
  ParticipationResponse
} from '@cleverbits-gmbh/iwimp-typescript-api'

import { ServiceBase } from 'lib/api/ServiceBase'

class CourseService extends ServiceBase {
  async getParticipations(): Promise<ParticipationResponse[]> {
    return new CoursesApi(this.authenticated()).getParticipations()
  }

  async getNextCourse(types: CourseTypeEnum[]): Promise<CourseResponse> {
    return new CoursesApi(this.authenticated()).getNextCourse({ types })
  }
}

export const courseService = new CourseService()
