export const validatePassword = (password: string) =>
  validateMinLength(password) &&
  validateUpperCaseLetter(password) &&
  validateLowerCaseLetter(password) &&
  validateNumber(password) &&
  validateSpecialChars(password)

export const validateMinLength = (password: string, minLength = 8) => password?.length >= minLength

export const validateUpperCaseLetter = (password: string, minOccurrence = 1): boolean =>
  Boolean(password) && (RegExp('[A-Z]').exec(password)?.length || 0) >= minOccurrence

export const validateLowerCaseLetter = (password: string, minOccurrence = 1): boolean => {
  return Boolean(password) && (RegExp('[a-z]').exec(password)?.length || 0) >= minOccurrence
}

export const validateNumber = (password: string, minOccurrence = 1): boolean =>
  Boolean(password) && (RegExp('[0-9]').exec(password)?.length || 0) >= minOccurrence

export const validateSpecialChars = (password: string, minOccurrence = 1): boolean =>
  Boolean(password) && (RegExp('[+-/\\(){}\\[\\]<>!§$%&=?*#€¿&_".,:;]').exec(password)?.length || 0) >= minOccurrence
