import {
  CertificateResponse,
  CertificatesApi,
  CertificateUpdateRequest,
  CertificateUploadRequest,
  CertificateUploadResponse,
  FileDownloadResponse
} from '@cleverbits-gmbh/iwimp-typescript-api'

import { fileService, OnMultiProgress, Progress } from 'lib/api/FileService'
import { ServiceBase } from 'lib/api/ServiceBase'
import { noop } from 'lib/utils'

class CertificateService extends ServiceBase {
  get(): Promise<CertificateResponse[]> {
    return new CertificatesApi(this.authenticated()).getCertificates()
  }

  async delete(id: number): Promise<void> {
    return new CertificatesApi(this.authenticated()).deleteCertificate({ id })
  }

  async update(id: number, req: CertificateUpdateRequest): Promise<void> {
    return new CertificatesApi(this.authenticated()).updateCertificate({ id, certificateUpdateRequest: req })
  }

  async upload(files: File[], onProgress: OnMultiProgress = noop): Promise<void> {
    const requests = files.map((file) => ({
      fileName: file.name,
      contentType: file.type,
      size: file.size
    }))
    const responses = await this.initiateCertificateUpload(requests)
    await Promise.all(
      responses.map(async (res, index) => {
        await this.doUpload(res.url, files[index], (progress) => onProgress(index, progress))
        await this.finishCertificateUpload(res.fileId)
      })
    )
  }

  private async initiateCertificateUpload(reqs: CertificateUploadRequest[]): Promise<CertificateUploadResponse[]> {
    return new CertificatesApi(this.authenticated()).initiateCertificateUpload({ certificateUploadRequest: reqs })
  }

  private async doUpload(url: string, file: File, onProgress: (progress: Progress) => void): Promise<void> {
    return fileService.doUpload(url, file, onProgress)
  }

  private async finishCertificateUpload(id: number): Promise<FileDownloadResponse> {
    return fileService.finishFileUpload(id)
  }
}

export const certificateService = new CertificateService()
