import {
  FileDownloadResponse,
  FilesApi,
  FileUploadRequest,
  FileUploadResponse
} from '@cleverbits-gmbh/iwimp-typescript-api'

import { ServiceBase } from 'lib/api/ServiceBase'
import { noop } from 'lib/utils'

export type Progress = {
  total: number
  uploaded: number
}
export type OnProgress = (progress: Progress) => void
export type OnMultiProgress = (index: number, progress: Progress) => void

class FileService extends ServiceBase {
  async upload(file: File, onProgress: OnProgress = noop): Promise<number> {
    const fileUploadResponse = await this.initiateFileUpload({
      fileName: file.name,
      contentType: file.type,
      size: file.size
    })
    await this.doUpload(fileUploadResponse.url, file, onProgress)
    await this.finishFileUpload(fileUploadResponse.id)
    return fileUploadResponse.id
  }

  private async initiateFileUpload(req: FileUploadRequest): Promise<FileUploadResponse> {
    return new FilesApi(this.authenticated()).initiateFileUpload({ fileUploadRequest: req })
  }

  async finishFileUpload(id: number): Promise<FileDownloadResponse> {
    return new FilesApi(this.authenticated()).finishFileUpload({ id })
  }

  async doUpload(url: string, file: File, onProgress: OnProgress): Promise<void> {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest()
      xhr.upload.onprogress = function onprogress(event) {
        onProgress({ total: event.total, uploaded: event.loaded })
      }
      xhr.onload = function onload() {
        if (xhr.status >= 200 && xhr.status < 300) {
          resolve()
        } else {
          reject(new Error(`Upload failed with status ${xhr.status}`))
        }
      }
      xhr.onerror = function onerror() {
        reject(new Error(`Upload failed with status`))
      }
      xhr.open('PUT', url)
      xhr.send(file)
    })
  }
}

export const fileService = new FileService()
